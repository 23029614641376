export const style = {
          color: {
            main: "#f2d8c2",
         //    yellow: "#f3ec9e",
         //    orange: "#ff8242",
         //    white: "#f4f0f1",
          },
         break: {
           xs: "360px",
           sm: "768px",
           md: "1024px",
           lg: "1200px",
         },
         projects: ["project-1", "project-2", "project-3"]
         //  fonts: [`"Helvetica Neue", sans-serif`],
         //  borderRadius: "10px",
       };