import React from "react";
// import { Switch, Route } from "react-router-dom";
import { useTrail } from "react-spring";

import "./styles/App.scss";

// import ScrollToTop from "../components/ScrollToTop";

import Cursor from "../components/Cursor";
import Footer from "../components/Footer";

// import HomePage from "./HomePage";
import Hero from "./HomePage/Hero";

// const Pages = () => {
//   return (
//     <Switch>
//       <Route exact path="/">
//         <HomePage />
//       </Route>
//     </Switch>
//   );
// }

const Layout = (props) => {
  return (
    <>
      <div className="view">
        <div className="site-layout">
          <div className="scroll-content" id="scroll-content">
            <Hero />
            {/* <ScrollToTop />
            <main className="page-main">
              <Pages />
            </main> */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

function App() {

  // Cursor
  const [trail, set] = useTrail(1, () => ({
    xy: [0, 0],
    config: { mass: 5, tension: 1000, friction: 60 },
  }));

  return (
    <div
      className="app -cursor"
      onMouseMove={(e) => set({ xy: [e.clientX, e.clientY] })}
    >
      <Layout />
      <Cursor trail={trail} />
    </div>
  );
}
export default App;
