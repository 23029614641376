var d = new Date();
var n = d.getFullYear();

export const data = {
  year: n,
  email: "tu@tomungerer.com",
  socials: [
    {
      id: 1,
      icon: "instagram",
      label: "Instagram",
      url: "https://www.instagram.com/tomungerer/",
    },
    {
      id: 2,
      icon: "mail",
      label: "Email",
      url: "mailto:tu@tomungerer.com",
    },
  ],
};
