import React, { useEffect, useState } from 'react'

import { CursorStyle, CursorText, CursorImage } from "./styles"
import { useLocation } from 'react-router-dom';

import { style } from "../../assets/variants"


const Cursor = ({trail}) => {

  const location = useLocation();
  const [cursorText, setCursorText] = useState("view case");
  const [cursorMedia, setCursorMedia] = useState({ type: "", src: "" });

  // -visible
  useEffect(() => {
    const cursor = document.getElementById("cursor");
    const app = document.getElementById("root");
    const onMouseEnter = () => {
      cursor.classList.add("-visible");
    }
    const onMouseLeave = () => {
      cursor.classList.remove("-visible");
    };
    app.addEventListener("mouseenter", onMouseEnter);
    app.addEventListener("mouseleave", onMouseLeave);
    return () => {
      app.removeEventListener("mouseenter", onMouseEnter);
      app.removeEventListener("mouseleave", onMouseLeave);
    };
  }, [])

  // -active
  useEffect(() => {
    const cursor = document.getElementById("cursor");
    const pointers = document.querySelectorAll("a,input,textarea,button,[data-cursor]");
    const onMouseDown = () => {
      cursor.classList.add("-active");
    };
    const onMouseUp = () => {
      cursor.classList.remove("-active");
    };
    pointers.forEach((el) => {
      el.addEventListener("mousedown", onMouseDown);
      el.addEventListener("mouseup", onMouseUp);
    });
    return () => {
      pointers.forEach((el) => {
        el.removeEventListener("mousedown", onMouseDown);
        el.removeEventListener("mouseup", onMouseUp);
      });
    };
  }, [location]);

  // -pointer
  useEffect(() => {
    const cursor = document.getElementById("cursor");
    const pointers = document.querySelectorAll("a,input,textarea,button,[data-cursor]");
    const onMouseEnter = () => {
      cursor.classList.add("-pointer");
    };
    const onMouseLeave = () => {
      cursor.classList.remove("-pointer");
    };
    pointers.forEach((el) => {
      el.addEventListener("mouseenter", onMouseEnter);
      el.addEventListener("mouseleave", onMouseLeave);
      el.addEventListener("click", onMouseLeave);
    });
    return () => {
      pointers.forEach((el) => {
        el.removeEventListener("mouseenter", onMouseEnter);
        el.removeEventListener("mouseleave", onMouseLeave);
        el.removeEventListener("click", onMouseLeave);
      });
    };
  }, [location]);

  // -[data-cursor]
  useEffect(() => {
    const cursor = document.getElementById("cursor");
    const pointers = document.querySelectorAll("[data-cursor]");
    const projects = style.projects.map((item) => `-color-${item}`);
    const classes = [
      "-pointer",
      "-opaque",
      "-text",
      "-media",
      "-media-lg",
      "-logo",
      ...projects,
    ];
    const onMouseEnter = (e) => {
      cursor.classList.add(e.target.getAttribute("data-cursor"));
    };
    const onMouseLeave = function (e) {
      cursor.classList.remove(e.target.getAttribute("data-cursor"));
    };
    const onMouseClick = function (e) {
      cursor.classList.remove(...classes);
    };
    pointers.forEach((el) => {
      el.addEventListener("mouseenter", onMouseEnter);
      el.addEventListener("mouseleave", onMouseLeave);
      el.addEventListener("click", onMouseClick);
    });
    return () => {
      pointers.forEach((el) => {
        el.removeEventListener("mouseenter", onMouseEnter);
        el.removeEventListener("mouseleave", onMouseLeave);
        el.removeEventListener("click", onMouseClick);
      });
    };
  }, [location]);

  // cursor text
  useEffect(() => {
    const pointers = document.querySelectorAll("[data-cursor-text]");
    const onMouseEnter = (e) => {
      if (cursorText !== e.target.getAttribute("data-cursor-text"))
        setCursorText(e.target.getAttribute("data-cursor-text"));
    };
    pointers.forEach((el) => {
      el.addEventListener("mouseenter", onMouseEnter);
    });
    return () => {
      pointers.forEach((el) => {
        el.removeEventListener("mouseenter", onMouseEnter);
      });
    };
  }, [cursorText]);

  // cursor media
  useEffect(() => {
    const images = document.querySelectorAll("[data-image-src]");
    const videos = document.querySelectorAll("[data-video-src]");
    const onMouseEnterImage = (e) => {
      if (cursorMedia.src !== e.target.getAttribute("data-image-src")) {
        setCursorMedia({
          type: "image",
          src: e.target.getAttribute("data-image-src"),
        });
      }
    };
    const onMouseEnterVideo = (e) => {
      if (cursorMedia.src !== e.target.getAttribute("data-video-src")) {
        setCursorMedia({
          type: "video",
          src: e.target.getAttribute("data-video-src"),
        });
        console.log(cursorMedia);
      }
    };
    images.forEach((el) => {
      el.addEventListener("mouseenter", onMouseEnterImage);
    });
    videos.forEach((el) => {
      el.addEventListener("mouseenter", onMouseEnterVideo);
    });
    return () => {
      images.forEach((el) => {
        el.removeEventListener("mouseenter", onMouseEnterImage);
      });
      videos.forEach((el) => {
        el.removeEventListener("mouseenter", onMouseEnterVideo);
      });
    };
  }, [cursorMedia]);

  const trans = (x, y) => `translate3d(${x}px,${y}px,0) translate3d(-50%,-50%,0)`;
  return (
    <>
      {trail.map((props, index) => (
        <CursorStyle
          key={index}
          style={{ transform: props.xy.interpolate(trans) }}
          className="-visible"
          id="cursor"
        >
          <CursorText>{cursorText}</CursorText>
          <CursorImage>
            <div className="cursor-media-inner" id="cursor-media-inner">
              {cursorMedia ? (
                <div>
                  {cursorMedia.type === "image" ? (
                    <img src={cursorMedia.src} alt="mouse illustration" />
                  ) : cursorMedia.type === "video" ? (
                    <video preload="auto" autoPlay loop muted playsInline>
                      <source src={cursorMedia.src} type="video/mp4" />
                    </video>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </CursorImage>
        </CursorStyle>
      ))}
    </>
  );
}

export default Cursor
