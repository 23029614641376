import styled from "styled-components";
// import { style } from "../../../assets/variants";
// import background from "../../../assets/rockywall.png";
// import background from "../../../assets/hero-fairshare.jpg";
import image from "../../../assets/hero.jpg";

export const HeroStyle = styled.header`
  position: relative;
  background: #000;
  color: #111;
  padding-top: 50px;
  overflow: hidden;
  color: white;
  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
  }
`;

export const HeroContainer = styled.div`
  width: 939px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  // padding-top: 50px;
`;

export const HeroImage = styled.div`
  padding-bottom: 50%;
  background: url(${image}) center bottom/100% auto no-repeat;
  z-index:1;
  position: relative;
  min-width: 500px;
  min-height: 240px;
`;
export const HeroLogo = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 110%;
  transform: translateX(-50%);
`;

export const SpotifyButtonStyle = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 200;
  @media (max-width: 385px) {
    display: none;
  }
`;