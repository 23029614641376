import React from 'react'
// import Logo from '../../../components/Logo'

import {
  HeroStyle,
  HeroContainer,
  HeroImage,
  // HeroLogo,
} from "./styles";

const Hero = () => {
  return (
    <HeroStyle className="hero">
      <HeroContainer className="hero-container container">
        <HeroImage />
        {/* <HeroLogo>
          <Logo />
        </HeroLogo> */}
      </HeroContainer>
    </HeroStyle>
  );
};

export default Hero
