
import { Link } from "react-router-dom";
import styled from "styled-components";
import {style} from "../../assets/variants";
import top from  "../../assets/footer-top.png";

export const OutroStyle = styled.footer`
  text-align: center;
  position: relative;
  color: ${style.color.main};
  background: #000;
  // margin-top: 100px;
  // &:before{
  //   content: '';
  //   position: absolute;
  //   top: -100px;
  //   left: 0;
  //   height: 100px;
  //   width: 100%;
  //   background: url(${top}) repeat-x;
  // }
`;

export const OutroContent = styled.div`
  position: relative;
  z-index: 1;
  padding: 22px 0 55px 0;
`;

export const OutroBody = styled.div`
`;

export const OutroLogo = styled.div`
  margin: 0 auto;
  max-width: 100%;
  width: 500px;
`;

export const OutroHeader = styled.div`
  font-size: 38px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.03em;
  @media (min-width: ${style.break.xs}) {
    font-size: 42px;
  }
  @media (min-width: ${style.break.sm}) {
    font-size: 60px;
  }
  @media (min-width: ${style.break.lg}) {
    font-size: 5.41667vw;
  }
  h2 {
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
  }
  a {
    position: relative;
    display: inline-block;
    padding: 0 0 8px 0;
    font-weight: 300;
    text-decoration: none;

    @media (min-width: ${style.break.sm}) {
      padding: 0 0 15px 0;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: currentColor;
      transition: transform 0.55s cubic-bezier(0.4, 0.22, 0.21, 1.04);
    }
    &:hover {
      &:before {
        transform: scaleX(0);
      }
    }
  }
`;

export const OutroFooter = styled.div`
  text-align: center;
  // @media (min-width: ${style.break.md}) {
  //   text-align: left;
  // }
`;

export const OutroGrid = styled.div`
  // @media (min-width: ${style.break.md}) {
  //   display: flex;
  //   align-items: center;
  // }
`;

export const OutroGridCol = styled.div`
  // @media (min-width: ${style.break.md}) {
  //   flex: 1;
  //   &.-right {
  //     padding: 0 0 0 40px;
  //     text-align: right;
  //   }
  // }
`;

export const OutroMail = styled.div`
  margin: 50px 0 0 0;
  // @media (min-width: ${style.break.md}) {
  //   margin: 0;
  // }
  a {
    position: relative;
    display: inline-block;
    padding: 0 0 5px 0;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.00464286em;
    text-decoration: none;
    &:after,
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: rgba(255, 255, 255, 0.3);
    }
    &:after {
      background: rgba(255, 255, 255, 0.7);
      transform: scaleX(0);
      transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
    }
    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
    @media (max-width: 320px) {
      font-size: 18px;
    }
  }
`;

export const OutroAddress = styled.div`
  margin: 28px auto 0 auto;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 400;
  line-height: 157%;
  @media (min-width: 1024px) {
    margin: 28px -30px 0 -30px;
  }
  @media (min-width: 1200px) {
    margin: 28px -35px 0 -35px;
  }
  address {
    display: block;
    font-style: normal;
    margin: 15px 0;
    @media (min-width: 1024px) {
      display: inline-block;
      margin: 0 30px;
    }
    @media (min-width: 1200px) {
      margin: 0 35px;
    }
    p {
      margin: 0;
    }
  }
`;

export const OutroLink = styled.div`
  margin: 30px 0 0 0;
  @media (min-width: 1024px) {
    margin: 18px 0 0 0;
  }
`;

export const OutroLinkItem = styled(Link)`
  position: relative;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px;
    height: 1px;
    transform: scaleX(0);
    transform-origin: right center;
    background: ${style.color.main};
    transition: transform 0.4s, transform-origin 0s;
  }
  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: left center;
    }
  }
`;

export const OutroGridSocial = styled.div`
  padding: 40px 0 0 0;
  @media (min-width: 1024px) {
    padding: 33px 0 0 0;
  }
  @media (min-width: 1200px) {
    margin: -15px;
  }
`;

export const OutroGridSocialRow = styled.div``;

export const OutroGridSocialItem = styled.a`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 63px;
  overflow: hidden;
  // opacity: 0.7;
  cursor: pointer;
  font-size: 20px;
  font-weight: 300;
  line-height: 64px;
  letter-spacing: 0.00464286em;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s;
  // @media (min-width: 768px) {
  //   width: 98px;
  //   height: 68px;
  //   line-height: 67px;
  // }
  // @media (min-width: 1200px) {
  //   width: calc(33.33% - 30px);
  //   height: auto;
  //   padding: 18px 0;
  //   line-height: normal;
  //   margin: 15px;
  //   opacity: 1;
  //   text-align: left;
  // }

  .outro-social-item-title {
    position: relative;
    display: inline-block;
    overflow: hidden;
    span {
      display: none;
      // @media (min-width: 1200px) {
      //   display: inline-block;
      //   position: relative;
      //   z-index: 1;
      //   color: transparent;
      //   transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
      //   &:after,
      //   &:before {
      //     content: attr(data-text);
      //     display: block;
      //     position: absolute;
      //     color: #fff;
      //   }
      //   &:before {
      //     top: 0;
      //     transform: skewY(0);
      //     transform-origin: right bottom;
      //     transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      //   }
      //   &:after {
      //     top: 105%;
      //     transform: skewY(7deg);
      //     transform-origin: left top;
      //     transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      //   }
      // }
    }
  }

  &:hover {
    opacity: 1;
    .outro-social-item-title {
      span {
        transform: translateY(-105%);
        &:before {
          transform: skewY(7deg);
        }
        &:after {
          transform: skewY(0);
        }
      }
    }
  }
  // svg {
  //   @media (min-width: 1200px) {
  //     display: none;
  //   }
  // }
`;

export const OutroCopyright = styled.div`
  font-size: smaller;
  margin-top: 34px;
`;


export const SpotifyButtonStyle = styled.div`
  margin: 40px auto 0;
`;