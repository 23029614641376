import styled from "styled-components";
import { animated } from "react-spring";

let defaultColor = '#f2d8c2';

export const CursorText = styled.div`
  position: absolute;
  top: -18px;
  left: -18px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0) rotate(10deg);
  opacity: 0;
  color: ${defaultColor};
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.01em;
  transition: opacity 0.4s, transform 0.3s;
  .-text & {
    opacity: 1;
    transform: scale(1);
  }
`;

export const CursorImage = styled.div`
         display: none;
         position: absolute;
         width: 350px;
         height: 350px;
         margin: -175px 0 0 -175px;
         .cursor-media-inner {
           filter: invert(1);
           position: relative;
           width: 100%;
           height: 100%;
           overflow: hidden;
           transform: scale(0);
           transform-origin: center center;
           opacity: 0;
           border-radius: 50%;
           -webkit-mask-image: -webkit-radial-gradient(
             circle,
             ${defaultColor} 100%,
             #000 100%
           );
           transition: transform 0.35s, opacity 0.2s 0.2s;
           img,
           video {
             width: 100%;
             height: auto;
             top: 0;
             left: 0;
           }
         }
         .-media & {
           display: block;
           .cursor-media-inner {
             opacity: 1;
             transform: scale(0.7);
             transition-delay: 0s, 0s;
           }
         }
         .-media.-media-lg & {
           .cursor-media-inner {
             transform: scale(1) !important;
           }
         }
       `;

export const CursorStyle = styled(animated.div)`
         @media (pointer: fine) {
           position: fixed;
           top: 0;
           left: 0;
           z-index: 150;
           contain: layout style size;
           pointer-events: none;
           will-change: transform;
           transition: opacity 0.3s, color 0.4s;
          //  color: ${(props) => (props.color ? props.color : "inherit")};

           &:before {
             content: "";
             position: absolute;
             top: -24px;
             left: -24px;
             display: block;
             width: 48px;
             height: 48px;
             transform: scale(0);
             background: currentColor;
             border-radius: 50%;
             transition: transform 0.3s ease-in-out, opacity 0.1s;
             color: ${defaultColor};
           }
           .-cursor & {
             &.-visible {
               &:before {
                 transform: scale(0.2);
               }
               &.-pointer {
                 &:before {
                   transform: scale(0.15);
                 }
               }
               &.-menu {
                 &:before {
                   transform: scale(1.32);
                 }
               }
               &.-logo {
                 mix-blend-mode: exclusion;
                 z-index: 152;
                 &:before {
                   transform: scale(1.32);
                   background-color: ${defaultColor};
                 }
               }
               &.-opaque {
                 mix-blend-mode: exclusion;
                 &:before {
                   transform: scale(1.32);
                   background-color: ${defaultColor};
                 }
               }
               &.-inverse {
                 color: ${defaultColor};
               }
               &.-text {
                 mix-blend-mode: normal;
                 &:before {
                   background: currentColor;
                   opacity: 0.85;
                   transform: scale(1.7);
                 }
               }
               &.-media {
                 mix-blend-mode: exclusion;
                 &:before {
                   transform: scale(1.32);
                   background-color: ${defaultColor};
                 }
                 &.-media-blend {
                   z-index: 150px;
                 }
               }
               &.-active {
                 &.-pointer {
                   &:before {
                     transform: scale(0.075);
                   }
                 }
                 &.-menu,
                 &.-logo,
                 &.-opaque,
                 &.-media {
                   &:before {
                     transform: scale(1.2);
                   }
                 }
                 &.-text {
                   &:before {
                     transform: scale(1.6);
                   }
                 }
               }
             }
           }
         }
       `;
