import React from 'react'
// import { Link } from 'react-router-dom';

// import Logo from "../Logo";
import {
  OutroStyle,
  OutroContent,
  // OutroBody,
  // OutroHeader,
  OutroFooter,
  // OutroGrid,
  // OutroGridCol,
  // OutroMail,
  // OutroLogo,
  // OutroAddress,
  // OutroLink,
  // OutroLinkItem,
  OutroGridSocial,
  OutroGridSocialRow,
  OutroGridSocialItem,
  OutroCopyright
} from "./styles";

import {data} from "./data"

import svgsprites from "../../assets/svgsprites.svg";



const SocialLink = ({ item }) => {
  return (
    <OutroGridSocialItem
      key={item.id}
      className={`outro-social-item -${item.icon}`}
      href={item.url}
      target="_blank"
      rel="noopener"
      data-cursor="-opaque"
      title={item.label}
    >
      <span className="outro-social-item-title">
        <span data-text={item.label}>{item.label}</span>
      </span>
      <svg className={`svgsprite -${item.icon}`}>
        <use xlinkHref={`${svgsprites}#${item.icon}`} />
      </svg>
    </OutroGridSocialItem>
  );
};


const Outro = ({cursor, setCursor, defaultCursor}) => {
  return (
    <OutroStyle id="site-footer">
      <OutroContent className="outro-content">
        {/* <OutroBody className="outro-body">
          <div className="container">
            <OutroHeader className="outro-header">
              <h2>Have an idea?</h2>
              <Link to="/contacts">Tell us about it</Link>
            </OutroHeader>
          </div>
        </OutroBody> */}
        <OutroFooter className="outro-footer">
          <div className="container">
            {/* <OutroLogo>
              <Logo />
            </OutroLogo> */}
            {/* <OutroGrid className="outro-grid"> */}
              {/* <OutroGridCol className="outro-grid-col -left"> */}
                {/* {data.email && <OutroMail className="outro-mail">
                  <a href={`mailto:${data.email}`}>
                    <em>
                      <span>{data.email}</span>
                    </em>
                  </a>
                </OutroMail>} */}
                {/* {data.address && <OutroAddress className="outro-address">
                  <address>
                    {data.address
                      ? data.address.map((line, i) => <p key={i}>{line}</p>)
                      : ""}
                  </address>
                </OutroAddress>} */}
                {/* <OutroLink className="outro-link">
                  <OutroLinkItem className="outro-link-item" to="/privacy/">
                    Privacy Policy
                  </OutroLinkItem>
                </OutroLink> */}
              {/* </OutroGridCol>
              <OutroGridCol className="outro-grid-col -right"> */}
                <OutroGridSocial className="outro-social">
                  <OutroGridSocialRow className="outro-social-row">
                    {data.socials
                      ? data.socials.length > 0
                        ? data.socials
                            // .filter((item, i) => i % 2 === 0)
                            .map((item, i) => (
                              <SocialLink key={item.id} item={item} />
                            ))
                        : ""
                      : ""}
                  </OutroGridSocialRow>
                  {/* <OutroGridSocialRow className="outro-social-row">
                    {data.socials
                      ? data.socials.length > 0
                        ? data.socials
                            .filter((item, i) => i % 2 !== 0)
                            .map((item, i) => (
                              <SocialLink key={item.id} item={item} />
                            ))
                        : ""
                      : ""}
                  </OutroGridSocialRow> */}
                </OutroGridSocial>
              {/* </OutroGridCol> */}
            {/* </OutroGrid> */}
            <OutroCopyright>
              <p>
                Copyright &copy; {data.year} Tom Ungerer.<br />
                All rights reserved.
              </p>
            </OutroCopyright>
          </div>
        </OutroFooter>
      </OutroContent>
    </OutroStyle>
  );
}

export default Outro
